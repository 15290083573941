import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import Img from "gatsby-image"

export default function AboutPage({ data }) {
  const { markdownRemark: page } = data
  const image = page.frontmatter.image.childImageSharp.fluid
  const { html } = page
  return (
    <Layout subTitle={"about"}>
      <div className={"tablet:flex tablet:mt-48"}>
        <div className={"p-5 leading-snug text-gray tablet:w-1/2"}>
          <HTMLContent content={html} />
        </div>
        <div className={"w-full tablet:w-1/2 tablet:mr-20"}>
          <Img
            fluid={{
              ...image,
            }}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      </div>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const AboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(
              maxWidth: 800
              traceSVG: {
                color: "lightgray"
                optTolerance: 0.4
                turdSize: 100
                alphaMax: 0.5
                turnPolicy: TURNPOLICY_MINORITY
              }
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
